<template>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="w-full space-y-8">
      <div>
        <img
          class="mx-auto h-40 w-auto"
          src="https://syion.com/images/logo.png"
          alt="Workflow"
        />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset Password
        </h2>
      </div>
      <form
        class="mt-8 space-y-6"
        action="#"
        method="POST"
        @submit.prevent="resetConfirmPassword"
        novalidate
      >
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email" class="sr-only">Email Address</label>
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
              v-model="email"
            />
          </div>
        </div>

        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="new_password" class="sr-only">New Password</label>
            <input
              id="new_password"
              name="new_password"
              type="password"
              autocomplete="new_password"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="New Password"
              v-model="new_password"
            />
          </div>
        </div>

        <div>
          <label for="code" class="block text-sm font-medium text-gray-700">
            2FA Code
          </label>
          <div class="mt-1">
            <input
              id="code"
              name="code"
              type="text"
              v-model="code"
              required
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Reset
          </button>
          <br />
          <button
            @click="cancelResetPassword"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "CreateTeam",
  data() {
    return {
      email: "",
      code: "",
      new_password: "",
      remember: "",
    };
  },
  methods: {
    async resetConfirmPassword() {
      try {
        Auth.forgotPasswordSubmit(this.email, this.code, this.new_password)
          .then(() => {
            this.$fire({
              title: "Reset Password",
              text: "Password reset successfully",
              type: "success",
              timer: 3000,
            });
            this.$router.push({ name: "Login" });
          })
          .catch((err) => {
            this.$fire({
              title: "Reset Password",
              text: err.message,
              type: "error",
              timer: 3000,
            });
          });
      } catch (error) {
        alert(error.message);
      }
    },
    cancelResetPassword() {
      try {
        this.$router.push({ name: "Login" });
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>
