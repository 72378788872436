<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="sm:m-auto sm:w-full p-6 lg:p-8 bg-white">
          <div
            class="px-8 py-4 shadow sm:rounded-lg sm:px-16 sm:py-8 flex justify-center"
          >
            <div class="flex-1 lg:max-w-2/3">
              <div
                class="bg-red-700 border-t-2 border-l-2 border-r-2 border-black rounded-t-lg"
              >
                <p class="p-4 font-bold text-base lg:text-xl">
                  Forgot Password &rarr;
                </p>
              </div>
              <div class="bg-white p-4 border-2 border-black rounded-b-lg">
                <br />
                <form
                  ref="loginForm"
                  class="space-y-2 lg:px-8"
                  @submit.prevent="formCheck"
                  novalidate
                >
                  <div>
                    <p class="text-black pb-5">
                      Please enter the email address that you want to reset
                      password.
                    </p>
                    <label
                      for="email"
                      class="block text-md font-bold text-red-700"
                    >
                      Email address
                    </label>
                    <div class="relative">
                      <div class="flex">
                        <input
                          id="email"
                          name="email"
                          v-model="email"
                          required
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-black text-black focus:outline-none focus:ring-red-700 focus:border-red-700 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="errors.length" class="text-red-500">
                    <b>Please correct the following error(s):</b>
                    <ul>
                      <li v-for="error in errors" :key="error">{{ error }}</li>
                    </ul>
                  </div>
                  <div class="flex justify-center">
                    <button
                      type="submit"
                      class="w-1/4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-blue-500 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-5"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <home-footer></home-footer>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
// import { Bus } from "../../evtbus/event-bus";
import api from "@/api/apiServices";

export default {
  name: "Login",
  components: {
    HomeHeader,
    HomeFooter,
  },
  async mounted() {},
  data() {
    return {
      user: this.$route.params.user,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          //eslint-disable-next-line
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      errors: [],
    };
  },
  methods: {
    formCheck() {
      this.errors = [];
      if (!this.email) {
        this.errors.push("Email required");
      }
      //eslint-disable-next-line
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.errors.push("E-mail must be valid");
      }
      if (this.errors.length == 0) {
        this.requestResetEmail();
      }
    },
    async requestResetEmail() {
      // Send confirmation code to user's email
      Auth.forgotPassword(this.email)
        // eslint-disable-next-line
        .then(() => {
          this.$fire({
            title: "Reset Password",
            text: `A verification code for reset password has been sent to your email.`,
            type: "success",
          }).then(() => {
            this.$router.push({
              name: "ForgotPasswordConfirm",
            });
          });
        })
        .catch((err) => {
          if (
            err.message == "User password cannot be reset in the current state."
          ) {
            api.resendPassword(this.email).then(
              this.$fire({
                title: "Reset Password",
                text: "Temporary password has been resent to your email.",
                type: "success",
              })
            );
          } else {
            this.$fire({
              title: "Reset Password",
              text: err.message,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
