<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="sm:m-auto sm:w-full p-6 lg:p-8 bg-white">
          <div
            class="px-8 py-4 shadow sm:rounded-lg sm:px-16 sm:py-8 flex justify-center"
          >
            <div class="flex-1 lg:max-w-2/3">
              <div
                class="bg-red-700 border-t-2 border-l-2 border-r-2 border-black rounded-t-lg"
              >
                <p class="p-4 font-bold text-base lg:text-xl">
                  Change Password &rarr;
                </p>
              </div>
              <div class="bg-white p-4 border-2 border-black rounded-b-lg">
                <br />

                <form
                  ref="loginForm"
                  class="space-y-2 lg:px-8"
                  @submit.prevent="formCheck"
                  novalidate
                >
                  <p class="text-black pb-5">
                    Please enter your the verification code you have received in
                    your email and new password:
                  </p>
                  <div>
                    <label
                      for="password"
                      class="block text-md font-bold text-red-700"
                    >
                      Verification Code
                    </label>
                    <div class="relative">
                      <div class="flex">
                        <input
                          id="verificationCode"
                          name="verificationCode"
                          v-model="verificationCode"
                          autocomplete="off"
                          required
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-black text-black focus:outline-none focus:ring-red-700 focus:border-red-700 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      for="password"
                      class="block text-md font-bold text-red-700"
                    >
                      Email
                    </label>
                    <div class="relative">
                      <div class="flex">
                        <input
                          id="email"
                          name="email"
                          v-model="email"
                          autocomplete="off"
                          required
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-black text-black focus:outline-none focus:ring-red-700 focus:border-red-700 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Please enter a new password for your account: -->
                  <div>
                    <label
                      for="password"
                      class="block text-md font-bold text-red-700"
                    >
                      New Password
                    </label>
                    <div class="relative">
                      <div class="flex">
                        <input
                          id="password"
                          name="password"
                          :type="passwordFieldType"
                          v-model="password"
                          autocomplete="current-password"
                          required
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-black text-black focus:outline-none focus:ring-red-700 focus:border-red-700 sm:text-sm"
                        />
                        <button
                          type="button"
                          @click="switchVisibility"
                          class="bg-blue-300 hover:bg-blue-300 font-bold px-3 py-2 rounded inline-flex items-center content-end"
                        >
                          <div v-if="passwordFieldType == 'password'">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.199.02-.393.057-.581 1.474.541 2.927-.882 2.405-2.371.174-.03.354-.048.538-.048 1.657 0 3 1.344 3 3zm-2.985-7c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 12c-2.761 0-5-2.238-5-5 0-2.761 2.239-5 5-5 2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"
                              />
                            </svg>
                          </div>
                          <div v-if="passwordFieldType == 'text'">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      for="password"
                      class="block text-md font-bold text-red-700"
                    >
                      Confirm New Password
                    </label>
                    <div class="relative">
                      <div class="flex">
                        <input
                          id="confirm password"
                          name="password"
                          :type="passwordFieldType"
                          v-model="confirmPassword"
                          autocomplete="current-password"
                          required
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-black text-black focus:outline-none focus:ring-red-700 focus:border-red-700 sm:text-sm"
                        />
                        <button
                          type="button"
                          @click="switchVisibility"
                          class="bg-blue-300 hover:bg-blue-300 font-bold px-3 py-2 rounded inline-flex items-center content-end"
                        >
                          <div v-if="passwordFieldType == 'password'">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.199.02-.393.057-.581 1.474.541 2.927-.882 2.405-2.371.174-.03.354-.048.538-.048 1.657 0 3 1.344 3 3zm-2.985-7c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 12c-2.761 0-5-2.238-5-5 0-2.761 2.239-5 5-5 2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"
                              />
                            </svg>
                          </div>
                          <div v-if="passwordFieldType == 'text'">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="errors.length" class="text-red-500">
                    <b>Please correct the following error(s):</b>
                    <ul>
                      <li v-for="error in errors" :key="error">{{ error }}</li>
                    </ul>
                  </div>
                  <div class="flex justify-center">
                    <button
                      type="submit"
                      class="w-1/4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-blue-500 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-5"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <home-footer></home-footer>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
// import { Bus } from "../../evtbus/event-bus";

export default {
  name: "ForgotPasswordConfirm",
  components: {
    HomeHeader,
    HomeFooter,
  },
  mounted() {},
  data() {
    return {
      email: "",
      verificationCode: new URL(location.href).searchParams.get("code")
        ? new URL(location.href).searchParams.get("code")
        : "",
      password: "",
      confirmPassword: "",
      passRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Password must be 8-20 characters",
        (v) =>
          /^(?=.*[0-9])/.test(v) || "Password must contain at least 1 number",
        (v) =>
          /^(?=.*[a-z])/.test(v) ||
          "Password must contain at least 1 lower case letter",
        (v) =>
          /^(?=.*[A-Z])/.test(v) ||
          "Password must contain at least 1 upper case letter",
        (v) =>
          /^(?=.*[!@#$%^&*"])/.test(v) ||
          'Password must contain at least 1 special character (!@#$%^&*")',
      ],
      errors: [],
      isVisible: false,
      passwordFieldType: "password",
    };
  },
  methods: {
    formCheck() {
      this.errors = [];
      if (!this.verificationCode) {
        this.errors.push("Verification code is required");
      }
      if (!this.password) {
        this.errors.push("Password is required");
      }
      if (this.password !== this.confirmPassword) {
        this.errors.push("New password does not match confirmed");
      }
      if (this.password.length < 8 || this.password.length > 20) {
        this.errors.push("Password must be 8-20 characters");
      }
      if (!/^(?=.*[0-9])/.test(this.password)) {
        this.errors.push("Password must contain at least 1 number");
      }
      if (!/^(?=.*[a-z])/.test(this.password)) {
        this.errors.push("Password must contain at least 1 lower case letter");
      }
      if (!/^(?=.*[A-Z])/.test(this.password)) {
        this.errors.push("Password must contain at least 1 upper case letter");
      }
      // if (!/^(?=.*[!@#$%^&*"])/.test(this.password)){
      //   this.errors.push('Password must contain at least 1 special character (!@#$%^&*")')
      // }
      if (this.errors.length == 0) {
        this.resetConfirmPassword();
      }
    },
    async resetConfirmPassword() {
      try {
        Auth.forgotPasswordSubmit(
          this.email,
          this.verificationCode,
          this.password
        )
          .then(() => {
            this.$fire({
              title: "Reset Password",
              text: `Password reset successfully.`,
              type: "success",
            }).then(() => {
              this.$router.push({ name: "Login" });
            });
          })
          .catch((err) => {
            this.$fire({
              title: "Fail to reset password",
              text: err.message,
              type: "error",
            });
          });
      } catch (error) {
        this.$fire({
          title: "Fail to reset password",
          text: error.message,
          type: "error",
        });
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>
