<template>
  <modal name="pdpa" :adaptive="true" height="auto" width="600">
    <div class="flex flex-col">
      <div
        class="p-2 bg-red-700 text-white text-tiny sm:text-lg font-semibold text-center flex-1"
      >
        <p>Personal Data Protection Policy</p>
      </div>
      <div class="p-4 flex flex-2 flex-col text-xxs sm:text-sm lg:text-base">
        <p>
          By registering as a member, you agree that the Knights Templar -
          Provincial Priory of South East Asia ("KTPPSEA") may collect, use and
          disclose any and all information provided as stipulated by the PDPA.
        </p>
        <br />
        <p>
          You agree that all personal data collected and/or processed by the
          KTPPSEA may include but not be limited to the purposes of
          communication, promotional material, reports, documentation,
          collaterals, programmes, and other information regarding the KTPPSEA
          as well as any other third parties with whom it may collaborate with
          from time to time; complying with any applicable laws or regulations,
          regulatory policies, industry codes of practice or guidelines,
          judgments, orders, directions or requests issued by any court, legal
          or regulatory bodies.
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "PdpaModal",
};
</script>
